import { apiHttpService } from 'app/api-http-service';
import { AssertionsBoardEntity, AssertionsQueryParams } from 'asserts-types';
import { TRACKING_FEATURES } from 'global-constants';
import { isTimestampInterval, stringToDate } from 'helpers/Date.helper';
import TrackingHelper from 'helpers/Tracking.helper';
import { omit } from 'lodash';
import { fetchGrafanaSlo, fetchSloConfig } from 'services/Slo.service';

export default function useAssertionsQueryParamsResolver() {
  const resolveQueryParams = async (queryParams: AssertionsQueryParams) => {
    // the flow for reading query params that come from alertmanager that includes asserts_entity_type
    // example http://localhost:8001/a/grafana-asserts-app/assertions?env[0]=dev&site[0]=dev&start=1698424984000-30m&end=1698424984000%2B30m&search=show service api-server&asserts_entity_type=Service&asserts_entity_name=api-server
    if (queryParams?.asserts_entity_type) {

      const url = new URL(window.location.href);

      // modifying URL for requesting entity
      url.searchParams.set(
        'start',
        stringToDate(url.searchParams.get('start') || '')
          .valueOf()
          .toString()
      );

      url.searchParams.set(
        'end',
        stringToDate(url.searchParams.get('end') || '')
          .valueOf()
          .toString()
      );

      const env = url.searchParams.get('env[0]');
      const site = url.searchParams.get('site[0]');
      const namespace = url.searchParams.get('namespace[0]');

      env && url.searchParams.set('env', env);
      site && url.searchParams.set('site', site);
      namespace && url.searchParams.set('namespace', namespace);

      url.searchParams.delete('env[0]');
      url.searchParams.delete('site[0]');
      url.searchParams.delete('namespace[0]');

      // sending all query params as a payload
      const entityKey = await apiHttpService
        .get<AssertionsBoardEntity | undefined>(`/api/plugins/grafana-asserts-app/resources/asserts/api-server/v1/entity${url.search}`)
        .then((res) => res.data);
      if (entityKey) {
        const updatedQueryParams: AssertionsQueryParams = {
          ...omit(queryParams, 'search'),
          workbenchEntities: { 0: entityKey },
        };
        TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.ALERTMANAGER);
        return updatedQueryParams;
      }
    }
    // the flow for reading url that contains grafana_slo_uuid that comes from alertmanager
    if (queryParams?.grafana_slo_uuid) {
      const sloConfig = await fetchGrafanaSlo(queryParams?.grafana_slo_uuid);
      if (sloConfig.entitySearch) {
        const updatedQueryParams: AssertionsQueryParams = {
          ...omit(queryParams, 'grafana_slo_uuid'),
          search: sloConfig.entitySearch,
          start: stringToDate(queryParams.start || '')
            .valueOf()
            .toString(),
          end: stringToDate(queryParams.end || '')
            .valueOf()
            .toString(),
        };
        TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.ALERTMANAGER);
        return updatedQueryParams;
      }
    }
    // the flow for reading url that contains slo_name that comes from alertmanager
    // TODO: deprecated, remove after we are migrated to grafana_slo_uuid
    if (queryParams?.slo_name) {
      const sloConfig = await fetchSloConfig(queryParams?.slo_name);
      if (sloConfig.entitySearch) {
        const updatedQueryParams: AssertionsQueryParams = {
          ...omit(queryParams, 'slo_name'),
          search: sloConfig.entitySearch,
          start: stringToDate(queryParams.start || '')
            .valueOf()
            .toString(),
          end: stringToDate(queryParams.end || '')
            .valueOf()
            .toString(),
        };
        TrackingHelper.trackNavigateToWorkbench(TRACKING_FEATURES.ALERTMANAGER);
        return updatedQueryParams;
      }
    }

    // resolving start and end dates in <ms>-<duration> format for example 1726040746000-30m
    if (queryParams?.start && queryParams?.end && isTimestampInterval(queryParams?.start)) {
      const updatedQueryParams: AssertionsQueryParams = {
        ...queryParams,
        start: stringToDate(queryParams.start).valueOf().toString(),
        end: stringToDate(queryParams.end).valueOf().toString(),
      };

      return updatedQueryParams;
    }
    return queryParams;
  };
  return { resolveQueryParams };
}
