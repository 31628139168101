import { useQuery } from '@tanstack/react-query';
import { Entity, EntityPropertyTypes, Scope, StringRules } from 'asserts-types';
import { searchEntities } from 'services/Entity.service';

export interface UseEntityParams {
  entityName?: string;
  entityType?: string;
  start: number;
  end: number;
  scope?: Scope;
}

export default function useEntity({ entityName = '', entityType = '', start, end, scope }: UseEntityParams) {
  return useQuery<Entity>(
    ['entity', entityName, entityType, start, end, scope],
    async () => {
      const { data } = await searchEntities(
        [
          {
            entityType,
            propertyMatchers: [
              {
                id: -1,
                name: 'name',
                op: StringRules.EQUALS,
                value: entityName,
                type: EntityPropertyTypes.STRING,
              },
            ],
          },
        ],
        start,
        end,
        {
          nameAndValues: {
            env: scope?.env ? [scope?.env] : undefined,
            site: scope?.site ? [scope?.site] : undefined,
            namespace: scope?.namespace ? [scope?.namespace] : undefined,
          },
        }
      );

      return data.entities[0] ?? null;
    },
    { enabled: !!entityName && !!entityType, keepPreviousData: true }
  );
}
