import { dateTime, dateTimeAsMoment, dateTimeParse } from '@grafana/data';
import moment, { unitOfTime, Moment } from 'moment';
const MATCH_TIMESTAMP_INTERVAL = /(\d{12,14})((-|\+)\d{1,})(s|m|h|d|w)/; // for example 1726040746000-30m

export function isTimestampInterval(value: string) {
  return MATCH_TIMESTAMP_INTERVAL.test(value);
}

export const stringToDate = (str: number | string): Moment => {
  if (typeof str === 'string') {
    const matchStr = MATCH_TIMESTAMP_INTERVAL.exec(str);
    if (isTimestampInterval(str) && matchStr) {
      return moment(Number(matchStr[1])).add(
        parseInt(matchStr[2], 10),
        matchStr[4] as unitOfTime.Base,
      );
    } else if (parseInt(str, 10)) {
      return dateTimeAsMoment(dateTimeParse(parseInt(str, 10)));
    } else {
      return dateTimeAsMoment(dateTimeParse(str));
    }
  }
  return moment(str);
};

export const dateToParams = (
  value: number | string,
): number | string => (typeof value === 'string' ? value : moment(value).valueOf());

export const parseStartAndEndDates = (start?: string | number, end?: string | number) => {
  if (start && end) {
    const numberStart = Number(start);
    const numberEnd = Number(end);
    const parsedStart = numberStart ? dateTime(numberStart) : stringToDate(start);
    const parsedEnd = numberEnd ? dateTime(numberEnd) : stringToDate(end);

    if (parsedStart.isValid() && parsedEnd.isValid()) {
      return {
        start: numberStart ? parsedStart.valueOf() : start,
        end: numberEnd ? parsedEnd.valueOf() : end,
      };
    }
  }

  return {};
};
