import React from 'react';
import { Button } from '@grafana/ui';

export function GiveFeeback() {
  return (
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSejnknktJcZBwY4JLF4wuVLXOl0xM2oXlLb5BSwfqqEt5XBEA/viewform"
      target="_blank"
      rel="noreferrer"
    >
      <Button variant="primary" fill="text" size="sm" icon="comment-alt">
        Give feedback
      </Button>
    </a>
  );
}
