import React from 'react';
import { Box, RefreshPicker, TimeRangePicker } from '@grafana/ui';
import useAppTimeRange from 'hooks/useAppTimeRange';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  isFetching?: boolean;
  showRefreshButton?: boolean;
}

export default function GlobalDateRangePicker(props: Props | undefined) {
  const { isFetching, showRefreshButton } = props || {};
  const { timeRange, onChange, onZoom, onMove } = useAppTimeRange();
  const queryClient = useQueryClient();

  return (
    <Box display="flex" gap={1}>
      <TimeRangePicker
        value={timeRange}
        onChange={onChange}
        onMoveBackward={() => onMove('back')}
        onChangeTimeZone={() => {}}
        onMoveForward={() => onMove('forward')}
        onZoom={onZoom}
        isOnCanvas
      />
      {showRefreshButton && (
        <RefreshPicker
          isOnCanvas={true}
          noIntervalPicker={true}
          onRefresh={() => queryClient.refetchQueries({ stale: true, type: 'active' })}
          onIntervalChanged={() => null}
          isLoading={isFetching}
        />
      )}
    </Box>
  );
}
