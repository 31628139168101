import React from 'react';
import EnvSiteSelectorsComponent from 'components/EnvSiteSelectors/EnvSiteSelectors.component';
import GlobalDateRangePicker from 'features/App/components/GlobalDateRangePicker/GlobalDateRangePicker';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useAppSelector } from 'app/store';

export function GlobalActionsContainer({
  isFetching,
  handleEnvChange,
}: {
  isFetching: boolean;
  handleEnvChange?: () => void;
}) {
  const styles = useStyles2(getStyles);
  const start = useAppSelector((state) => state.app.start);
  const end = useAppSelector((state) => state.app.end);

  return (
    <div className={styles.actionsContainer}>
      <EnvSiteSelectorsComponent start={start} end={end} onChange={handleEnvChange} />
      <GlobalDateRangePicker showRefreshButton isFetching={isFetching} />
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    actionsContainer: css({
      display: 'flex',
      gap: theme.spacing(2),
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    }),
  };
}
