import React, { FunctionComponent, memo, useCallback, useMemo } from 'react';

import { PluginPage } from '@grafana/runtime';
import { Box, Button, LoadingBar, LoadingPlaceholder, Text, useStyles2 } from '@grafana/ui';
import { AssertionsViewType } from 'asserts-types';
import AssertionsTreeComponent from 'components/AssertionsTree/AssertionsTree.component';
import { GiveFeeback } from 'components/GiveFeedback';
import { GlobalActionsContainer } from 'components/GlobalActionsContainer/GlobalActionsContainer';
import { setTimeRange } from 'features/App/App.slice';
import AssertionSearchContainer from 'features/AssertionSearch/AssertionSearch.container';
import EntityDetailsContainer from 'features/EntityDetails/EntityDetails.container';
import { DEFAULT_FORCE_LAYOUT_OPTIONS } from 'global-constants';
import { ConnectedProps, connect } from 'react-redux';
import { stringToDate } from '../../helpers/Date.helper';
import { clearWorkbench, setExpandedSaafeFilter, setShowSearch, setViewType } from './Assertions.slice';
import AssertionsGraphComponent from './components/AssertionsGraph/AssertionsGraph.component';
import AssertionsList from './components/AssertionsList/AssertionsList.component';
import AssertionsSummaryViewComponent from './components/AssertionsSummaryView/AssertionsSummaryView.component';
import AssertionsTopMenu from './components/AssertionsTopMenu/AssertionsTopMenu.component';
import EmptyAssertions from './components/EmptyAssertions/EmptyAssertions.component';
import useAssertionsHistoryChanges from './hooks/useAssertionsHistoryChanges';
import useEntityAssertions from './hooks/useEntityAssertions';
import useEntityAssertionsSummary from './hooks/useEntityAssertionsSummary';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const connector = connect(
  (state: RootState) => ({
    workbenchEntities: state.assertions.workbenchEntities,
    start: state.app.start,
    end: state.app.end,
    viewType: state.assertions.viewType,
    selectedItems: state.assertions.selectedItems,
    activeEntityDetails: state.app.activeEntityDetails,
    showSearch: state.assertions.showSearch,
    showRelationships: state.entities.showRelationships,
    showNodeNames: state.entities.showNodeNames,
    expandedSaafeFilter: state.assertions.expandedSaafeFilter,
    search: state.assertions.search,
  }),
  {
    setShowSearch,
    setViewType,
    setExpandedSaafeFilter,
    setTimeRange,
    clearWorkbench,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Assertions: FunctionComponent<PropsFromRedux> = ({
  workbenchEntities,
  start,
  end,
  viewType,
  selectedItems,
  activeEntityDetails,
  showSearch,
  setShowSearch,
  setViewType,
  setExpandedSaafeFilter,
  expandedSaafeFilter,
  search,
  setTimeRange,
  clearWorkbench,
}) => {
  useAssertionsHistoryChanges();

  const {
    data: assertionsData,
    isFetching: isFetchingAssertions,
    isPreviousData: isPrevAssertionsData,
    isFetched: isEntityAssertionsFetched,
  } = useEntityAssertions();

  const {
    data: assertionsSummaryData,
    isFetching: isFetchingAssertionsSummary,
    isPreviousData: isPrevAssertionsSummaryData,
    isFetched: isEntityAssertionsSummaryFetched,
  } = useEntityAssertionsSummary();

  const changeDateRange = useCallback(
    (start: number | string, end: number | string) => {
      setTimeRange({ start, end });
    },
    [setTimeRange]
  );

  const startDate = useMemo(() => stringToDate(start).valueOf(), [start]);
  const endDate = useMemo(() => stringToDate(end).valueOf(), [end]);

  const isFetching = viewType === AssertionsViewType.SUMMARY ? isFetchingAssertionsSummary : isFetchingAssertions;

  const noResultsFound =
    viewType === AssertionsViewType.SUMMARY
      ? isEntityAssertionsSummaryFetched && assertionsSummaryData?.summaries.length === 0
      : isEntityAssertionsFetched && assertionsData?.assertions.length === 0;

  const isEmpty = !Boolean(search || workbenchEntities.length) || noResultsFound;

  // we need to hide previous data from cache (from prev search) to not confuse users
  // for example we have loaded Show all pods
  // then we try to load Show all nodes through global search and while it's loading it will hide Show all pods data
  // if we work with workbench in workbench entity keys added mode (no search) the prev data should be shown
  const isPrevious =
    viewType === AssertionsViewType.SUMMARY ? isPrevAssertionsSummaryData && search : isPrevAssertionsData && search;

  const styles = useStyles2(getStyles);

  return (
    <PluginPage
      subTitle={
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Text color="secondary" weight="regular">
              (<Text weight="bold">{workbenchEntities.length}</Text>{' '}
              {workbenchEntities.length === 1 ? 'Entity' : 'Entities'} added)
            </Text>
            {!!workbenchEntities.length && (
              <Button size="sm" fill="text" icon="trash-alt" variant="destructive" onClick={() => clearWorkbench()}>
                Clear
              </Button>
            )}
          </Box>
          <GiveFeeback />
        </Box>
      }
      actions={<GlobalActionsContainer isFetching={isFetching} />}
    >
      <Box display="flex" direction="column" height="100%">
        <AssertionsTopMenu assertions={assertionsData?.assertions} />
        <div className={styles.assertionsContainer}>
          {isFetching && (
            <div className={styles.loadingBarContainer}>
              <LoadingBar width={300} />
            </div>
          )}
          <div className={styles.subcontainer}>
            {isFetching && isPrevious && !isEmpty && (
              <div className={styles.refetchPlaceholder}>
                <LoadingPlaceholder text="Loading..." />
              </div>
            )}
            {viewType === AssertionsViewType.SUMMARY && !isEmpty && !isPrevious && (
              <AssertionsSummaryViewComponent
                start={start}
                end={end}
                changeDateRange={changeDateRange}
                data={assertionsSummaryData}
              />
            )}
            {viewType === AssertionsViewType.GRAPH && !isEmpty && !isPrevious && (
              <AssertionsGraphComponent
                start={start}
                end={end}
                layout={DEFAULT_FORCE_LAYOUT_OPTIONS}
                isFetchingWorkbench={isFetchingAssertions}
              />
            )}
            <AssertionsList
              show={viewType === AssertionsViewType.BY_ENTITY && !isEmpty && !isPrevious}
              selectedItems={selectedItems}
              expandedSaafeFilter={expandedSaafeFilter}
              setExpandedSaafeFilter={setExpandedSaafeFilter}
              start={startDate}
              end={endDate}
              changeDateRange={changeDateRange}
              assertionsData={assertionsData}
              isFetching={isFetchingAssertions}
            />
            {viewType === AssertionsViewType.MIND_MAP && !isEmpty && !isPrevious && (
              <AssertionsTreeComponent
                show
                hideResizer
                onLinkClick={() => {
                  setViewType(AssertionsViewType.BY_ENTITY);
                }}
                data={assertionsData?.assertionRollupDto}
              />
            )}
            {isEmpty && <EmptyAssertions showNoResultsFound={noResultsFound} />}
          </div>
          {showSearch && <AssertionSearchContainer />}
        </div>
      </Box>
      {activeEntityDetails && <EntityDetailsContainer />}
    </PluginPage>
  );
};

export default connector(memo(Assertions));

function getStyles(theme: GrafanaTheme2) {
  return {
    assertionsContainer: css({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }),
    loadingBarContainer: css({
      position: 'absolute',
      top: '-1px',
      left: 0,
      right: 0,
    }),
    subcontainer: css({
      display: 'flex',
      height: 0,
      flex: 1,
    }),
    refetchPlaceholder: css({
      width: '100%',
      height: '100%',
      minHeight: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  };
}
