import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Button, ComponentSize, Icon, Toggletip, useStyles2 } from '@grafana/ui';
import { AssertionSeverity, Entity, EntityAssertion } from 'asserts-types';
import { UseEntityParams } from 'hooks/useEntity';
import React from 'react';
import EntityAssertionsPopover from './EntityAssertionsPopover';
import TrackingHelper from 'helpers/Tracking.helper';
import useMultipleEntities from 'hooks/useMultipleEntities';
import { flatMap } from 'lodash';
import useBackendStatus from 'hooks/useBackendStatus';

type Severity = AssertionSeverity | 'none';

export interface EntityAssertionsWidgetProps {
  query: UseEntityParams;
  size?: ComponentSize;
  source?: string;
}

export function EntityAssertionsWidget({ query, size, source }: EntityAssertionsWidgetProps) {
  const { isLoading, data } = useMultipleEntities(query);
  const severity = getMaxSeverity(data?.map((entity) => entity.assertion?.assertions));
  const styles = useStyles2(getStyles(severity));
  const { isLoading: backendLoading, data: backendStatus } = useBackendStatus({ ignoreSetupStatus: true });

  if (isLoading || !data || backendLoading || !backendStatus?.enabled || data.length === 0) {
    return null;
  }

  const assertionCount = getAssertionCount(data);
  return (
    <Toggletip
      onOpen={() => {
        TrackingHelper.trackExternalWidgetOpened(source ?? 'unset');
      }}
      content={
        <EntityAssertionsPopover entities={data} query={query} source={source} assertionCount={assertionCount} />
      }
    >
      <Button className={styles.button} fill="outline" size={size}>
        {severity !== 'none' && (
          <Icon name={severity === 'warning' ? 'exclamation-triangle' : 'exclamation-circle'} className={styles.icon} />
        )}
        Assertions ({assertionCount})
        <Icon name={'angle-down'} className={styles.icon} />
      </Button>
    </Toggletip>
  );
}

function getStyles(severity: Severity) {
  return function (theme: GrafanaTheme2) {
    const color = getColorFromSeverity(severity, theme);
    return {
      button: css({
        borderColor: color,
        color: color,
        '&:hover': {
          borderColor: `color-mix(in srgb, ${color} 50%, ${color} 50%)`,
          color: `color-mix(in srgb, ${color} 50%, ${color} 50%)`,
          background: 'transparent',
        },
      }),
      icon: css({
        color: color,
        marginRight: theme.spacing(1),
      }),
    };
  };
}

function getAssertionCount(entities: Entity[]) {
  return entities.reduce((acc, entity) => acc + (entity?.assertion?.assertions?.length ?? 0), 0);
}

function getMaxSeverity(assertions?: Array<EntityAssertion['assertions']>): Severity {
  const flattened = flatMap(assertions);
  if (flattened.length === 0) {
    return 'none';
  }
  return (
    flattened?.reduce<Severity>((sev, assertion) => {
      if (assertion?.severity === 'critical') {
        return 'critical';
      }
      if (assertion?.severity === 'warning' && sev !== 'critical') {
        return 'warning';
      }
      if (assertion?.severity === 'info' && sev !== 'critical' && sev !== 'warning') {
        return 'info';
      }
      return sev;
    }, 'none') ?? 'none'
  );
}

function getColorFromSeverity(severity: Severity, theme: GrafanaTheme2) {
  switch (severity) {
    case 'critical':
      return theme.colors.error.text;
    case 'warning':
      return theme.colors.warning.text;
    case 'info':
      return theme.colors.info.text;
    case 'none':
      return theme.colors.text.secondary;
  }
}
