/**
 *
 * EnvSiteSelectors
 *
 */

import React, { memo, FunctionComponent, useMemo } from 'react';
import { stringToDate } from '../../helpers/Date.helper';
import { connect, ConnectedProps } from 'react-redux';
import { setSelectedSite, setSelectedEnv } from '../../features/App/App.slice';
import useEnvSiteOptions from 'hooks/useEnvSiteOptions';
import { Box, InlineField, InlineFieldRow, MultiSelect, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { css } from '@emotion/css';

interface IProps {
  start: number | string;
  end: number | string;
  onChange?: () => void;
}

const connector = connect(
  (state: RootState) => ({
    selectedSite: state.app.selectedSite,
    selectedEnv: state.app.selectedEnv,
    envColorsMap: state.app.envColorsMap,
  }),
  {
    setSelectedSite,
    setSelectedEnv,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

const allOption = { value: 'all', label: 'all' };

const EnvSiteSelectors: FunctionComponent<IProps & PropsFromRedux> = ({
  start,
  end,
  setSelectedSite,
  setSelectedEnv,
  selectedSite,
  selectedEnv,
  onChange,
  envColorsMap,
}) => {
  const styles = useStyles2(getStyles);

  const startMs = useMemo(() => stringToDate(start).valueOf(), [start]);
  const endMs = useMemo(() => stringToDate(end).valueOf(), [end]);

  const { data: envSiteOptions, isFetching } = useEnvSiteOptions({
    start: startMs,
    end: endMs,
  });

  const handleSelectEnv = (values: string[]) => {
    if (values.includes('all') && values[values.length - 1] === 'all') {
      setSelectedEnv([]);
    } else {
      setSelectedEnv(values.filter((v) => v !== 'all'));
    }
    onChange && onChange();
  };

  const handleSelectSite = (values: string[]) => {
    if (values.includes('all') && values[values.length - 1] === 'all') {
      setSelectedSite([]);
    } else {
      setSelectedSite(values.filter((v) => v !== 'all'));
    }
    onChange && onChange();
  };

  const envOptions = useMemo(() => {
    const options =
      envSiteOptions?.scopeValues.env?.map((option) => ({
        label: (
          <Box display="flex" alignItems="center">
            <span
              className={styles.colorCircle}
              style={{
                background: envColorsMap?.[option] || '#000000',
              }}
            ></span>
            {option}
          </Box>
        ),
        value: option,
      })) || [];
    // casting here because the label can be an element as well as a string. This works fine, but the type definition doesnt reflect this
    const cast = options as unknown as SelectableValue<string>[];
    cast.unshift(allOption);
    return cast;
  }, [envColorsMap, envSiteOptions?.scopeValues.env, styles.colorCircle]);

  const siteOptions = useMemo<SelectableValue<string>[]>(() => {
    const options =
      envSiteOptions?.scopeValues.site?.map((v) => ({
        value: v,
        label: v,
      })) || [];
    options.unshift(allOption);
    return options;
  }, [envSiteOptions]);

  const envValue = selectedEnv.length ? selectedEnv : [allOption];
  const siteValue = selectedSite.length ? selectedSite : [allOption];

  return (
    <InlineFieldRow data-intercom-target="env-site-selectors" className={styles.fieldRow}>
      <InlineField label="env">
        <MultiSelect
          className={styles.minWidth}
          value={envValue}
          options={envOptions}
          isLoading={isFetching}
          onChange={(values) => handleSelectEnv(values.map((v) => v.value || ''))}
        />
      </InlineField>
      <InlineField label="site">
        <MultiSelect
          className={styles.minWidth}
          value={siteValue}
          options={siteOptions}
          isLoading={isFetching}
          onChange={(values) => handleSelectSite(values.map((v) => v.value || ''))}
        />
      </InlineField>
    </InlineFieldRow>
  );
};

export default connector(memo(EnvSiteSelectors));

function getStyles(theme: GrafanaTheme2) {
  return {
    minWidth: css({
      minWidth: '148px',
    }),
    colorCircle: css({
      borderRadius: '50%',
      width: '7px',
      height: '7px',
      display: 'block',
      marginRight: '5px',
    }),
    fieldRow: css({ flexGrow: 1, justifyContent: 'flex-end', gap: theme.spacing(1) }),
  };
}
