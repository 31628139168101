import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Button, Collapse, Divider, Text, useStyles2 } from '@grafana/ui';
import { Entity, EntityAssertion, EntityFilterPropertyMatcher } from 'asserts-types';
import AssertionsListInfoComponent from 'components/AssertionsListInfo/AssertionsListInfo.component';
import GraphNodeRcaButtonsComponent from 'components/GraphinGraph/components/GraphNodeRcaButtons/GraphNodeRcaButtons.component';
import { TRACKING_FEATURES } from 'global-constants';
import { UseEntityParams } from 'hooks/useEntity';
import EntitiesOpenIcon from 'icons/EntitiesOpenIcon';
import React, { useMemo } from 'react';
import { CONNECTED_ENTITY_TYPES } from './constants';
import { useHandleViewConnectedEntities } from './useHandleViewConnectedEntities';

interface IProps {
  query: UseEntityParams & { additionalMatchers?: EntityFilterPropertyMatcher[] };
  entities: Entity[];
  source?: string;
  assertionCount: number;
}

export default function EntityAssertionsPopover({ entities, query, source, assertionCount }: IProps) {
  const styles = useStyles2(getStyles);
  const [envCollapse, setEnvCollapse] = React.useState<{ [key: string]: boolean }>({});

  const { connectedCount, connectedTypes, types, envSiteNS, assertionsList } = useMemo(() => {
    return entities.reduce(
      (acc, entity) => {
        const connectedCount = entity.connectedEntityTypes
          ? Object.entries(entity.connectedEntityTypes)
              .filter(([entityType]) => entityType !== 'Assertion' && CONNECTED_ENTITY_TYPES.includes(entityType))
              .reduce((acc, [_, entityCount]) => acc + entityCount, 0)
          : 0;

        const envSiteNS = [
          entity.scope?.env,
          entity.scope?.site,
          entity.properties.otel_namespace || entity.scope?.namespace || 'none',
        ]
          .filter(Boolean)
          .join(' | ');

        acc.assertionsList[envSiteNS] = entity.assertion?.assertions;

        Object.keys(entity.connectedEntityTypes ?? []).forEach((connectedType) => {
          acc.connectedTypes.add(connectedType);
        });
        return {
          connectedCount: acc.connectedCount + connectedCount,
          connectedTypes: acc.connectedTypes,
          types: acc.types.add(entity.type),
          envSiteNS: acc.envSiteNS.add(envSiteNS),
          assertionsList: acc.assertionsList,
        };
      },
      {
        connectedCount: 0,
        connectedTypes: new Set<string>(),
        types: new Set<string>(),
        envSiteNS: new Set<string>(),
        assertionsList: {} as { [key: string]: EntityAssertion['assertions'] },
      }
    );
  }, [entities]);

  const entitiesForWorkbench = useMemo(() => {
    return entities
      .filter((entity) => entity.assertion?.assertions?.length)
      .map((entity) => ({
        name: entity.name,
        type: entity.type,
        scope: entity.scope,
      }));
  }, [entities]);

  const handleViewConnectedEntities = useHandleViewConnectedEntities({
    query,
    entities,
    source,
    connectedTypes,
  });

  return (
    <div>
      <h4>Asserts troubleshooting</h4>
      <Divider />
      <p className={styles.subtitle}>Entity name</p>

      <Text>{entities?.[0]?.name}</Text>
      <Divider />
      <p className={styles.subtitle}>Entity type{types.size > 1 && 's'}</p>
      {Array.from(types).map((type) => (
        <Text key={type}>{type}</Text>
      ))}
      <Divider />
      <p className={styles.subtitle}>Assertions by environment | site | namespace</p>
      <div className={styles.assertionScroll}>
        {Array.from(envSiteNS).map((envSiteNS) => {
          return (
            <Collapse
              key={envSiteNS}
              label={`${envSiteNS} (${assertionsList[envSiteNS]?.length || 0})`}
              isOpen={envCollapse[envSiteNS]}
              onToggle={(isOpen) => {
                setEnvCollapse((prev) => ({ ...prev, [envSiteNS]: isOpen }));
              }}
              collapsible
            >
              {!assertionsList[envSiteNS]?.length ? (
                <span>No assertions firing</span>
              ) : (
                <AssertionsListInfoComponent assertions={assertionsList[envSiteNS]} />
              )}
            </Collapse>
          );
        })}
      </div>
      <Divider />
      <div className={styles.buttonGroup}>
        {assertionCount > 0 && (
          <GraphNodeRcaButtonsComponent
            entityInfo={entitiesForWorkbench}
            trackingFeature={TRACKING_FEATURES.ENTITY_ASSERTIONS_WIDGET}
            source={source}
          />
        )}
        <Button
          fill="outline"
          variant="secondary"
          className={styles.connectedEntitiesButton}
          icon={<EntitiesOpenIcon />}
          fullWidth
          onClick={() => handleViewConnectedEntities()}
        >
          <span className={styles.buttonContent}>View connected entities ({connectedCount})</span>
        </Button>
      </div>
    </div>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    subtitle: css({
      color: theme.colors.text.secondary,
      marginBottom: theme.spacing(1),
    }),
    connectedEntitiesButton: css({
      justifyContent: 'center',
    }),
    buttonContent: css({
      marginLeft: '.25rem',
    }),
    buttonGroup: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    }),
    assertionScroll: css({
      maxHeight: '400px',
      overflowY: 'auto',
    }),
  };
}
