import { StringRules, EntityPropertyTypes, Entity, EntityFilterPropertyMatcher } from 'asserts-types';
import {
  setActiveEntityDetails,
  setTimeRange,
  setQueryParamsWereRead,
  setSelectedEnv,
  setSelectedSite,
} from 'features/App/App.slice';
import { setShowAdvancedSearch, clearEntities, setSearchObject } from 'features/Entities/Entities.slice';
import { TRACKING_FEATURES, ROUTES } from 'global-constants';
import TrackingHelper from 'helpers/Tracking.helper';
import { prefixRoute } from 'utils/utils.routing';
import { CONNECTED_ENTITY_TYPES } from './constants';
import { useReturnToPrevious } from '@grafana/runtime';
import { UseEntityParams } from 'hooks/useEntity';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

interface HandleViewConnectedArgs {
  query: UseEntityParams & { additionalMatchers?: EntityFilterPropertyMatcher[] };
  entities: Entity[];
  source?: string;
  connectedTypes?: Set<string>;
}

export function useHandleViewConnectedEntities({ query, source, entities, connectedTypes }: HandleViewConnectedArgs) {
  const dispatch = useDispatch();
  const setReturnToPrevious = useReturnToPrevious();
  const navigate = useNavigate();
  return () => {
    if (source) {
      TrackingHelper.trackNavigateToEntityGraph(TRACKING_FEATURES.ENTITY_ASSERTIONS_WIDGET, source ?? 'unset');
    }

    dispatch(setActiveEntityDetails());
    setReturnToPrevious(source ?? 'previous');
    dispatch(setTimeRange({ start: query.start, end: query.end }));
    dispatch(setShowAdvancedSearch(true));
    dispatch(clearEntities());
    dispatch(setQueryParamsWereRead(true)); // we need to switch the mode so asserts app will use redux store as a source of data not the query params
    const propertyMatchers = [
      {
        id: 0,
        name: 'name',
        op: StringRules.EQUALS,
        type: EntityPropertyTypes.STRING,
        uom: '',
        value: entities[0].name,
      },
      ...(query.additionalMatchers ?? []),
    ];

    if (query.scope?.env) {
      dispatch(setSelectedEnv([query.scope.env]));
    } else {
      dispatch(setSelectedEnv([]));
    }
    if (query.scope?.site) {
      dispatch(setSelectedSite([query.scope.site]));
    } else {
      dispatch(setSelectedSite([]));
    }
    if (query?.scope?.namespace) {
      propertyMatchers.push({
        id: 3,
        name: 'namespace',
        op: StringRules.EQUALS,
        type: EntityPropertyTypes.STRING,
        uom: '',
        value: query.scope.namespace,
      });
    }

    dispatch(
      setSearchObject({
        filterCriteria: [
          {
            entityType: entities[0].type,
            propertyMatchers,
            connectToEntityTypes: Array.from(connectedTypes ?? []).filter((connectedType) =>
              CONNECTED_ENTITY_TYPES.includes(connectedType)
            ),
          },
        ],
      })
    );
    navigate(prefixRoute(ROUTES.ENTITIES_GRAPH));
  };
}
