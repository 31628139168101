import { useQuery } from '@tanstack/react-query';
import { Entity, EntityFilterPropertyMatcher, EntityPropertyTypes, StringRules } from 'asserts-types';
import { searchEntities } from 'services/Entity.service';
import { UseEntityParams } from './useEntity';

export default function useMultipleEntities({
  entityName = '',
  entityType = '',
  start,
  end,
  scope,
  additionalMatchers,
}: UseEntityParams & { additionalMatchers?: EntityFilterPropertyMatcher[] }) {
  return useQuery<Entity[]>(
    ['entity', entityName, entityType, start, end, scope, additionalMatchers],
    async () => {
      const { data } = await searchEntities(
        [
          {
            entityType,
            propertyMatchers: [
              {
                id: -1,
                name: 'name',
                op: StringRules.EQUALS,
                value: entityName,
                type: EntityPropertyTypes.STRING,
              },
              ...(additionalMatchers ?? []),
            ],
          },
        ],
        start,
        end,
        {
          nameAndValues: {
            env: scope?.env ? [scope?.env] : undefined,
            site: scope?.site ? [scope?.site] : undefined,
            namespace: scope?.namespace ? [scope?.namespace] : undefined,
          },
        }
      );

      return data.entities;
    },
    { enabled: !!entityName && !!entityType, keepPreviousData: true }
  );
}
