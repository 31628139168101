import { Point } from '@antv/g2/lib/dependents';
import { EdgeConfig, GraphData, NodeConfig } from '@antv/g6';
import { MessageDescriptor } from 'react-intl';
import { DataQuery } from '@grafana/schema';

export interface EntityServerError {
  message: string;
  status: string;
  timestamp: string;
  subErrors: { field: string; message: string }[];
}

export interface ScopeCriteria {
  nameAndValues: {
    env?: string[];
    site?: string[];
    namespace?: string[];
  };
}

export type LegendConfig = Record<string, LegendConfigItem>;

export interface LegendConfigItem {
  color: string;
  shape: 'line' | 'area' | 'dash' | 'triangle';
  field?: string;
}

export interface LegendItem {
  field?: string;
  name: string;
}

export interface ServerError {
  error: string;
  message: string;
  subErrors?: { message: string; rejectedValue: string; field: string }[];
  path: string;
  status: number;
  timestamp: string;
}
export interface EntityAppearance {
  color: string;
  shape: string;
  icon: string;
}

export type EntityAppearanceOverride = Omit<EntityAppearance, 'default' | 'shape'> & {
  entityType: string;
};

export type EntitiesDisplayConfig = Record<string, EntityAppearance>;

export type EdgesDisplayConfig = Record<
  string,
  {
    color: string;
    default?: boolean;
  }
>;

export interface DisplayConfig {
  graph: {
    defaultEntity: EntityAppearance;
    defaultEdge: {
      color: string;
    };
    entities: EntitiesDisplayConfig;
    edges: EdgesDisplayConfig;
    overrides: {
      entities: EntitiesDisplayConfig;
      edges: EdgesDisplayConfig;
    };
  };
}

export type KpiDisplayView =
  | 'appO11yAppView'
  | 'k8sAppView'
  | 'frontendO11yAppView'
  | 'awsAppView'
  | 'defaultDashboard'
  | 'additionalDashboard'
  | 'frameworkDashboard'
  | 'runtimeDashboard'
  | 'logsView'
  | 'tracesView'
  | 'propertiesView'
  | 'metricsView';

export type KpiDisplayConfig = {
  [key in KpiDisplayView]: boolean;
};

export interface AppContainerState {
  menuExpanded: boolean;
  selectedEnv: string[];
  selectedSite: string[];
  start: number | string;
  end: number | string;
  showEnvSiteTag: boolean;
  envColorsMap?: Record<string, string>;
  licenseLimitBanner?: {
    title: MessageDescriptor;
    content: MessageDescriptor;
  };
  globalSearchOpened: boolean;
  // if it's true app will use redux store as a source of truth
  // if it's false app will read query params from url and fill redux store with the values
  queryParamsWereRead: boolean;

  activeEntityDetails?: ActiveEntityDetails;
}

export type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : T;

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type Mandate<T extends {}, K extends keyof T> = Omit<T, K> & {
  [MK in K]-?: NonNullable<T[MK]>;
};

export interface SubMenuItem {
  icon: React.ReactElement<any>;
  text: string;
  navLink?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  allowedPermissions?: Permission[];
  disabled?: boolean;
}

export interface LicenseLimits {
  allowedWBEntityCount: number;
  license: {
    allowedNodes: number;
    product: 'freemium' | 'freemium_test' | 'enterprise' | 'saas' | 'saas_test';
    status: 'perpetual' | 'expired' | 'active' | 'cached';
    message?: string;
    type: 'full' | 'trial';
    expires?: number;
  };
}

export interface LicenseUsage {
  license: {
    allowedNodes: number;
    product: 'freemium' | 'freemium_test' | 'enterprise' | 'saas' | 'saas_test';
    status: 'perpetual' | 'expired' | 'active' | 'cached';
    message?: string;
    type: 'full' | 'trial';
    expires?: number;
  };
  allowedNodes: number;
  activeNodes: number;
}

export interface OAuth2Config {
  authScheme: string;
  authorizeEndpoint: string;
  clientId: string;
  clientSecret: string;
  grantType: string;
  logoutUrlTemplate: string;
  name: string;
  params: Record<string, string>;
  providerId: string;
  scope: string;
  tokenEndpoint: string;
  userInfoEndpoint: string;
  profileMap: Record<'name' | 'email' | 'picture' | 'providerUserId' | 'locale' | 'hostDomain', string>;
}

export interface PrometheusConfig {
  url: string;
  env?: string;
  password?: string;
  site?: string;
  username?: string;
  index?: number;
  forRules: boolean;
  forDashboards: boolean;
}

export interface OAuth2ConfigForm extends Omit<OAuth2Config, 'params'> {
  params: { key: string; value: string }[];
}

export interface LicenseInfo {
  key: string;
  productId: string;
  token: string;
}

export interface Sort<T = string> {
  order: 'asc' | 'desc';
  field: T;
}
export interface AssertionLogsParam {
  labels: Record<string, string>;
  entityProperties: EntityProperties;
  name: string;
  entityName: string;
  entityType: string;
  scope: Scope | undefined;
}

export interface AssertionScoresRes {
  assertionScoresForRootEntity: AssertionScore;
  assertionScoresForTopNEntities: (Omit<AssertionScore, 'percentage'> & {
    percentage: string;
  })[];
  entityAssertionsDtos: EntityInfo[];
  assertionRollupDto: AssertionRollup[];
  timeStepIntervalMs: number;
  timeWindow: {
    start: number;
    end: number;
  };
  graphData: Entity[];
}

export interface AssertionScore {
  type: string;
  name: string;
  metrics: {
    metric: { asserts_severity: 'critical' | 'warning' | 'info' };
    values: ChartPoint[];
  }[];
  totalScore: number;
  percentage: number;
  scope: Scope;
  assertionClusters: Cluster[];
  properties?: EntityProperties;
  nameWithNamespace?: string;
  inboundClientErrorBreached: boolean;
  severityWiseTotalScores: {
    warning?: number;
    info?: number;
    critical?: number;
  };
}

export type SAAFE = 'saturation' | 'amend' | 'anomaly' | 'failure' | 'error';

export interface SummaryMetric {
  id: string;
  assertionName: string;
  alertName: string;
  category: string;
  hovered?: boolean;
  healthStates: HealthState[];
  labels: Record<string, string>[];
  nestedSummaries: SummaryMetric[];
}

export interface AssertionSummary {
  id: string;
  type: string;
  name: string;
  scope: Scope;
  entityProperties: EntityProperties;
  timeLines: SummaryMetric[];
  hovered?: boolean;
  scoreMetrics?: Metric[];
  scoresMaxY?: number;
}

export interface AssertionSummaryRes {
  summaries: Omit<
    Omit<AssertionSummary, 'timeLines'> & {
      timeLines: (Omit<SummaryMetric, 'id' | 'nestedSummaries'> & {
        nestedSummaries: Omit<SummaryMetric, 'id'>[];
      })[];
    },
    'id'
  >[];
  graphData: Entity[];
  assertionScores: (Omit<AssertionScore, 'percentage'> & {
    percentage?: string;
  })[];
  timeStepIntervalMs: number;
  timeWindow: {
    start: number;
    end: number;
  };
}

export interface AssertionSummaryResProcessed {
  summaries: AssertionSummary[];
  graphData: Entity[];
  assertionScores: AssertionScore[];
  timeStepIntervalMs: number;
  timeWindow: {
    start: number;
    end: number;
  };
}

export interface AuthInfo {
  loginUrl: string;
  name?: string;
  icon?: string;
  params: Record<string, string>;
}

export interface TenantHeader {
  tenantId: string;
  name: string;
  domain: string;
  enabled?: boolean;
}

export interface UserTenants {
  tenants: TenantHeader[];
}

export interface AuthResponse {
  logoutUrl: string;
  identity: UserIdentity;
  profile: UserProfile;
  tenant?: TenantHeader;
}

export interface TenantAuthResponse {
  tenants: TenantHeader[];
}

export interface Definition {
  bindings: {
    service_type?: string;
  } & Record<string, string | undefined>;
  entityName?: string;
  boundDescription: string;
  definitionId: number;
  description?: string;
  id: number;
  // score: number;
  filterCriteria?: EntityFilterCriteria[];
  // entityKeys?: AssertionsBoardEntity[];
}

export interface AssertionsContainerState {
  workbenchEntities: AssertionsBoardEntity[];
  viewType: AssertionsViewType;
  expandedItems: string[];
  selectedItems: string[];
  showOnlySelected: boolean;
  linesHeight: Record<string, number>;
  showSearch: boolean;
  highlightedItemId?: string;
  nullAsZeroByHash: Record<string, boolean>;
  showSavedSearch: boolean;
  showWithAssertions: boolean;
  hideOldAssertions: boolean;
  oldAssertionHours: number;
  scrollPosition: number;
  alertCategories: SAAFE[];
  storedCollapsedItems: Record<string, boolean>;
  timelineViewType: TimelineViewType;
  expandedSaafeFilter: boolean;
  activeChartPoint?: Point;
  labelsFilter: Record<string, string[]>;
  sort: Sort<'time' | 'totalScore'>;
  expandedSummaryItems: string[];
  summaryLeftPanelWidth: number;
  withRCA: boolean;
  selectedTime: number | undefined;
  search?: string;
  chartTooltip: ChartTooltipType;
}

export enum AssertionsViewType {
  BY_ENTITY = 'BY_ENTITY',
  GRAPH = 'GRAPH',
  MIND_MAP = 'MIND_MAP',
  SUMMARY = 'SUMMARY',
}

export function isValidAssertionsViewType(test: any): test is AssertionsViewType {
  return Object.values(AssertionsViewType).includes(test);
}

export enum ChartTooltipType {
  DEFAULT = 'DEFAULT',
  SHARED_LINE = 'SHARED_LINE',
  SHARED_TOOLTIP = 'SHARED_TOOLTIP',
}

export enum TimelineViewType {
  HEATMAP = 'HEATMAP',
  GRADIENT = 'GRADIENT',
}

export type AssertionsBoardEntity = Pick<Entity, 'name' | 'type' | 'scope'>;

export type AssertionsQueryParams =
  | ({
      workbenchEntities?: Record<number, { name: string; type: string; scope?: Scope }>;
      search?: string;
      view?: string;
      asserts_entity_type?: string;
      slo_name?: string;
      grafana_slo_uuid?: string;
    } & AppQueryParams)
  | undefined
  | null;

export type AppQueryParams =
  | {
      start?: string;
      end?: string;
      env?: Record<number, string>;
      site?: Record<number, string>;
      ed?: ActiveEntityDetails;
    }
  | undefined
  | null;

export interface EntitiesQueryParams extends Partial<Omit<Definition & AppQueryParams, 'id' | 'filterCriteria'>> {
  search?: string;
  // bubble view panels
  bvp?: string[];
  // entity columns
  ec?: string[];
  // kpi columns
  kc?: string[];
  view?: EntitiesTab;
  filterCriteria?: Optional<EntityFilterCriteria, 'propertyMatchers'>[];
}

export type EntitiesTab = 'list' | 'graph' | 'bubble';

export interface TopDefinition extends Pick<Definition, 'boundDescription'> {
  rank?: number;
}

export interface DefinitionResponse {
  items: Definition[];
  offset: number;
  totalResults: number;
  pageResults: number;
  maxResults: number;
}

export enum DefinitionSearchTypes {
  EntityObject = 8,
  EntityConnections = 7,
  ConnectedEntities = 4,
}

export interface ChartPoint {
  time: number;
  value?: number | null;
  values?: number[] | null[];
}

export interface ProcessedChartPoint extends ChartPoint {
  name?: string;
  singleThresholdName?: string;
  areaThresholdName?: string;
  singleThresholdValue?: number | null;
  crossingRangeValues?: (number | null | undefined)[];
  crossingRangeName?: string;
}

export interface Threshold {
  type: 'minmax' | 'single';
  name: string;
  labels: Record<string, string>;
  values: ChartPoint[];
}

export interface Metric {
  name: string;
  values: ChartPoint[];
  query?: string;
  color?: string;
  fillZeros?: boolean;
  metric?: Record<string, string>;
}

export interface EntityWithMetric {
  type: string;
  name: string;
  metrics: Metric[];
}

export interface EntityAssertionDetails {
  type: string;
  name: string;
  assertionName: string;
  timeWindow: {
    start: number;
    end: number;
  };
  timeStepIntervalMs: number;
  thresholds: Threshold[];
  metrics: Metric[];
  entities?: EntityWithMetric[];
  notificationRuleName?: string;
}

export interface AdditionalEntityAssertionDetails {
  name: string;
  description: string;
  group: string;
  expression: string;
  interval: string;
  labels: Record<string, string>;
  metrics: { name: string; type: string; help: string; unit: string }[];
}

export interface KpiDisplayGroup {
  topLevelGroup: string;
  chartGroup: string;
}

export interface KpiDto {
  displayGroup: KpiDisplayGroup;
  displayName: string;
  thresholds: Threshold[];
  metrics: Metric[];
}

export interface EntityKpiDetails {
  id: number;
  type: string;
  name: string;
  timeWindow: {
    start: number;
    end: number;
  };
  kpiDtos: KpiDto[];
}

export interface EntityKpiSummary {
  id: number;
  type: string;
  name: string;
  timeWindow: {
    start: number;
    end: number;
  };
  kpis: { name: string; value: number }[];
}

export interface EntityDash {
  dashName: string;
  dashUri: string;
}

export interface EntityDashboardResponse {
  defaultDash: EntityDash;
  customDash: EntityDash;
  additionalDash: EntityDash;
  frameworkDash: EntityDash;
  runtimeDash: EntityDash;
}

export interface EntityType {
  entityType: string;
  labelsForName: string[];
}

export interface EntityTypes {
  entities: EntityType[];
}

export interface AssertionDetailBucket {
  bucketDate: string;
  bucketTimestamp: number;
  count: number;
}

export interface AssertionDetail {
  name: string;
  properties: {};
  buckets: AssertionDetailBucket[];
}

export interface AssertionResponse {
  start: string;
  end: string;
  step: string;
  details: AssertionDetail[];
}

export class HeatmapData {
  xLabels: string[];
  yLabels: string[];
  data: number[][];

  constructor(xLabels: string[], yLabels: string[], data: number[][]) {
    this.xLabels = xLabels;
    this.yLabels = yLabels;
    this.data = data;
  }
}

// export enum EntityTypeNames {
//   Deployment = 'Deployment',
//   Pod = 'Pod',
//   Namespace = 'Namespace',
//   Node = 'Node',
//   Container = 'Container',
//   ServiceInstance = 'ServiceInstance',
//   PersistantVolumeClaim = 'PersistantVolumeClaim',
//   k8sService = 'k8sService',
//   StatefulSet = 'StatefulSet',
// }

export interface Edge {
  destination: number;
  id: number;
  properties: { _created: number; _updated: number; cardinality: string };
  cardinality: string;
  _created: number;
  _updated: number;
  source: number;
  type: string;
  trafficPresent: boolean;
  callsPerMinute: number | undefined;
}

export type EntityProperties = Partial<Record<string, string | number>> & {
  _created?: number;
  _updated?: number;
};

export type AssertionSeverity = 'warning' | 'critical' | 'info';

export interface EntityAssertion {
  severity?: AssertionSeverity;
  amend?: boolean;
  assertions?: {
    assertionName: string;
    severity: AssertionSeverity;
    category: string;
    entityType: string;
  }[];
}

export interface HealthState extends EntityAssertion {
  start: number;
  end: number;
  context: Record<string, string>;
}

export interface Cluster {
  start: number;
  end: number;
  assertionStates: HealthState[];
  assertionSummaries: {
    summary: string;
    category: string;
  }[];
}

export interface EntityHealthState {
  name: string;
  type: string;
  healthStates: HealthState[];
  labels: Record<string, string>;
  displayLabels: Record<string, string>;
}

export interface DetailedEntityAssertion {
  name: string;
  alertName: string;
  entities: EntityHealthState[];
}

export interface HierarchicalAssertion {
  id: number;
  level: number;
  lastUpdateTime: number;
  hash: string;
  name: string;
  type: string;
  healthStates: HealthState[];
  clusters: Cluster[];
  assertionName?: string;
  assertionNames: string[];
  alertName?: string;
  labels: Record<string, string>;
  properties: EntityProperties | undefined;
  displayLabels: Record<string, string>;
  nestedTimelines: HierarchicalAssertion[];
  kpiDashboardURL?: string;
  rootEntityName: string;
  rootEntityType: string;
  scope: Scope | undefined;
  percentage: number;
  totalScore?: number;
  inboundClientErrorsBreached?: boolean;
  scoreMetrics: Metric[] | undefined;
  nameWithNamespace: string | undefined;
  rootInfoHealthStates: HealthState[];
}
export interface EntityInfo {
  itemId: string;
  type: string;
  name: string;
  timeWindow: {
    start: number;
    end: number;
  };
  assertions: DetailedEntityAssertion[];
  connectedAssertions: DetailedEntityAssertion[];
  allAssertions: HierarchicalAssertion[];
  scope: Scope;
  inboundClientErrorsBreached: boolean;
  nameWithNamespace?: string;
}

export interface AssertionRollup {
  assertionCount: number;
  labels: Record<string, string>;
  name: string;
  nextLevel?: AssertionRollup[];
  timelineHashes?: number[];
  pathHashesToLinkedGroups: number[][];
  criticalCount: number;
  infoCount: number;
  warningCount: number;
  type?: string;
  scope?: Scope;
}

export interface AssertionRollupProcessed {
  id: string;
  labels: Record<string, string>;
  name: string;
  entityType?: string;
  children: AssertionRollupProcessed[];
  collapsed: boolean;
  timelineHashes?: number[];
  level: number;
  mergedChildren?: AssertionRollupProcessed[];
  pathHashesToLinkedGroups?: number[][];
  criticalCount: number;
  infoCount: number;
  warningCount: number;
  assertionCount: number;
  scope?: Scope;
}

export interface AssertionsResponseRaw {
  assertions: {
    name: string;
    type: string;
    scope: Scope | undefined;
    inboundClientErrorsBreached: boolean;
    allAssertions: Array<
      Omit<
        HierarchicalAssertion,
        | 'level'
        | 'nameWithNamespace'
        | 'scoreMetrics'
        | 'properties'
        | 'rootEntityName'
        | 'rootEntityType'
        | 'lastUpdatedTime'
      >
    >;
  }[];
  assertionScores: (Omit<AssertionScore, 'percentage'> & {
    percentage?: string;
  })[];
  aggregateAssertionScores: AssertionScore;
  assertionRollupDto: AssertionRollup[];
  numEntitiesFound: number;
  numEntitiesReturned: number;
  timeStepIntervalMs: number;
  timeWindow: { start: number; end: number };
  graphData: Entity[];
}

export interface AssertionsResponseProcessed {
  assertions: HierarchicalAssertion[];
  assertionScores: AssertionScore[];
  aggregateAssertionScores: AssertionScore;
  assertionRollupDto: AssertionRollup[];
  numEntitiesFound: number;
  numEntitiesReturned: number;
  timeStepIntervalMs: number;
  timeWindow: { start: number; end: number };
  graphData: Entity[];
  maxAssertionScoreValue: number;
  alertCategoriesCountMap: Record<SAAFE, number>;
}

export interface Entity {
  id: number;
  name: string;
  type: string;
  assertion: EntityAssertion | undefined;
  connectedAssertion: EntityAssertion | undefined;
  properties: EntityProperties;
  connectedEntityTypes?: Record<string, number>;
  scope: Scope | undefined;

  // the fields below is assigned by client
  activeConnectedEntityType?: string;
  parentEntityId?: number;
  nameWithNamespace?: string;
  edgeWithActiveElement?: Edge;
}

export interface EntityResponse {
  data: {
    edges: Edge[];
    entities: Entity[];
    searchResultsMaxLimitHit?: boolean;
  };
  timeCriteria: {
    start: number;
    end: number;
  };
  adjustedTimeCriteria: boolean;
  stepDuration: string | null;
  type: 'graph';
  fromEntityId?: number;
}

export type ProcessedEntityResponse = EntityResponse & {
  graphData: GraphCustomData;
};

export type Order = 'asc' | 'desc';

export interface TypeKpiMapItem {
  type: string;
  kpis: string[];
}

export interface KpiGroup {
  name: string;
  kpis: { name: string; displayName: string; unit: string }[];
}

export interface KpiConfig {
  kpisByEntityType: TypeKpiMapItem[];
  kpiGroups: KpiGroup[];
}
export type EntityPopover = 'view-settings' | 'filter-settings';
export interface EntityContainerState {
  searchObject: EntitySearchObject;
  selectedOnly: boolean;
  searchName: string;
  entityListColumns: string[];
  activeEntity: Entity | undefined;
  activeNode: GraphCustomNode | undefined;
  typeFilter: string[];
  graphTypeScopeFilter: TypeScopeFilter[];
  nameSearchQuery: string;
  graphPadding: [number, number, number, number];
  history: Entity[];
  searchDefinition: Omit<Definition, 'id'> | null | undefined;
  showRelationships: boolean;
  showNodeNames: boolean;
  showFullNodeNameText: boolean;
  showCallRates: boolean;
  highlightSelected: boolean;
  order: Order;
  orderBy: string;
  activeView: EntitiesTab;
  showAdvancedSearch: boolean;
  topSearches: TopDefinition[];
  bubbleViewPanels: string[];
  bubbleViewActiveNodeName?: string;
  selectedKpi: string[];
  kpiSettings: KpiConfig;
  propByKpis: PropByKpis;
  monitoringStatus: MonitoringStatus[];
  search?: string;
}

export interface TraceUrlParams {
  threshold: ChartPoint[];
  values: ChartPoint[];
}

export type ActiveEntityDetails = Pick<Entity, 'type' | 'name' | 'scope'> &
  Partial<TraceUrlParams> & {
    properties?: EntityProperties;
    additionalLabels?: Record<string, string>;
    alertName?: string;
    start: number | string;
    end: number | string;
    tab: ListTabs;
  };

export type ActiveEntityDetailsPayload = Partial<ActiveEntityDetails> & Pick<Entity, 'type' | 'name' | 'scope'>;

export interface EntityDetailsContainerState {
  start?: number | string;
  end?: number | string;
}

export type ListFeatures = 'entities' | 'topInsights' | 'assertions' | 'incidents';

export type ListTabs =
  | 'info'
  | 'kpi'
  | 'path'
  | 'customKpi'
  | 'service-kpi'
  | 'logs'
  | 'traces'
  | 'metrics'
  | 'appO11y'
  | 'k8s'
  | 'app-o11y'
  | 'frontend-o11y'
  | 'csp'
  | 'additionalKpi'
  | 'frameworkDash'
  | 'runtimeDash';

export interface AssertionSearchContainerState {
  search: AssertionSearchForm;
}

export interface AssertionSearchForm {
  entityType: AssertionEntityTypes;
  searchCriteria: SearchCriteria[];
}

export interface SearchCriteria {
  labelMatchers: SearchRule[];
}

export interface SearchRule {
  name: string;
  value: string;
  op: StringRules;
}

export enum AssertionEntityTypes {
  SERVICE = 'Service',
  NAMESPACE = 'Namespace',
  NODE = 'Node',
  ASSERTION = 'Assertion',
  TOPIC = 'Topic',
  POD = 'Pod',
}

export interface AssertionSearchResult {
  name: string;
  type: string;
  scope: Scope;
}

export type TypeScopeFilter = Pick<Entity, 'type' | 'scope'>;

export interface KpiSummary {
  kpiValue: number;
  assertionSummary?: EntityAssertion;
}

export interface PropByKpis {
  [key: string]: Record<string, KpiSummary>;
}

export interface Scope {
  env?: string;
  site?: string;
  namespace?: string;
}

export interface EnityContextMenuItem {
  key: string;
  title?: string | React.ReactNode;
  group?: string;
  onClick?: (item: Entity) => void;
  setTitle?: (item: Entity) => string | React.ReactNode;
  hideOption?: (item: Entity) => boolean;
  value?: number;
}

export interface MonitoringStatus {
  entityKey: Pick<Entity, 'name' | 'type' | 'scope'>;
  latency: boolean;
  resource: boolean;
  traffic: boolean;
}

export interface NodeStyle {
  size: number;
  fill: string;
  fontSize: number;
  fontColor: string;
  strokeFirstLevelColor: string;
  strokeSecondLevelColor: string;
  strokeSecondLevelWidth: number;
  strokeFirstLevelWidth: number;
  activeBgStroke?: string;
}

export interface GraphCustomNode extends NodeConfig {
  entityType: string;
  entityName: string;
  showLabels?: boolean;
  showFullLengthLabels?: boolean;
  expandedFrom?: string;
  properties: EntityProperties;
  style?: Partial<NodeStyle> & {
    iconColor?: string;
    opacity?: number;
    cursor?: string;
    stroke?: string;
    lineWidth?: number;
  };
  disabled?: boolean;
  hidden?: boolean;
  assertion: EntityAssertion | undefined;
  connectedAssertion: EntityAssertion | undefined;
  label: string;
  connectedEntityTypes?: Record<string, number>;
  activeConnectedEntityType?: string;
  parentEntityId?: number;
  fullLabel?: string;
  scope: Scope | undefined;
  valueLabel?: string;
  disableTooltip?: boolean;
  summaryMetrics?: SummaryMetric[];
  iconOverride?: string;
}

export interface GraphCustomEdge extends EdgeConfig {
  expandedFrom?: string;
  showLabels?: boolean;
  disabled?: boolean;
  trafficPresent: boolean;
  callsPerMinute: number | undefined;
}

export interface GraphCustomData extends GraphData {
  nodes: GraphCustomNode[];
  edges: GraphCustomEdge[];
}

export enum Languages {
  EN = 'en',
}

export interface LanguageState {
  locale: Languages;
}

export type LocaleMessages = Record<Languages, Record<string, string>>;

export interface UserPermission {
  name: Permission;
  description: string;
}

export interface UserRole {
  name: string;
  description: string;
  visibility: string;
  permissions: UserPermission[];
}

export interface UserProfile {
  userId: string;
  email: string;
  profilePic?: string;
  locale: string;
  hostedDomain: string;
}

export interface UserIdentity {
  tenantId: string;
  userId: string;
  name?: string;
  providerId: string;
  providerUserId: string;
  role: UserRole;
  created: number;
  accessKey?: string;
}

export enum Permission {
  USER = 'USER',
  RULE_THRESHOLD = 'RULE_THRESHOLD',
  MANAGE_ALERTS = 'MANAGE_ALERTS',
  CONFIG_PROM_RULES = 'CONFIG_PROM_RULES',
  CUSTOM_DASHBOARD = 'CUSTOM_DASHBOARD',
  MANAGE_SLO = 'MANAGE_SLO',
  MANAGE_INTEGRATIONS = 'MANAGE_INTEGRATIONS',
  LIST_USERS = 'LIST_USERS',
  MANAGE_USER_ROLES = 'MANAGE_USER_ROLES',
  INVITE_USERS = 'INVITE_USERS',
  ACCESS_KEY_ALLOWED = 'ACCESS_KEY_ALLOWED',
  CONFIG_AWS_CLOUDWATCH = 'CONFIG_AWS_CLOUDWATCH',
  CONFIG_AWS_EXPORTER = 'CONFIG_AWS_EXPORTER',
  CONFIG_RELABEL_RULES = 'CONFIG_RELABEL_RULES',
  CONFIG_AUTH = 'CONFIG_AUTH',
  MANAGE_LICENSE_INFO = 'MANAGE_LICENSE_INFO',
  CONFIG_PROMETHEUS = 'CONFIG_PROMETHEUS',
  CONFIG_MODEL_RULES = 'CONFIG_MODEL_RULES',
  CONFIG_TENANT_CREDENTIAL = 'CONFIG_TENANT_CREDENTIAL',
  READ_TRACE_CONFIG = 'READ_TRACE_CONFIG',
  WRITE_TRACE_CONFIG = 'WRITE_TRACE_CONFIG',
  IMPORT_CONFIG = 'IMPORT_CONFIG',
  EXPORT_CONFIG = 'EXPORT_CONFIG',
  DELETE_USER = 'DELETE_USER',
  GRAFANA_ADMIN = 'GRAFANA_ADMIN',
  CONFIG_OTEL_COLLECTOR = 'CONFIG_OTEL_COLLECTOR',
  WRITE_OTEL_COLLECTOR = 'WRITE_OTEL_COLLECTOR',
  WRITE_AWS_CLOUDWATCH = 'WRITE_AWS_CLOUDWATCH',
  WRITE_PROM_RULES = 'WRITE_PROM_RULES',
  CONFIG_ALERTMANAGER = 'CONFIG_ALERTMANAGER',
  WRITE_ALERTMANAGER = 'WRITE_ALERTMANAGER',
  DISABLE_PROM_RULES = 'DISABLE_PROM_RULES',
}

export interface UserList {
  currentPage: number;
  identities: UserIdentity[];
  nextUri: string;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export interface InviteUsersRes {
  invitations: { message?: string; success: boolean; email: string }[];
}

export interface UserInvite {
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
  loginUrl: string;
}
export interface InviteUsersForm {
  invitations: UserInvite[];
}

export interface EntitySearchObject {
  filterCriteria: EntityFilterCriteria[];
}

export interface EntityFilterCriteria {
  entityType: string;
  propertyMatchers: EntityFilterPropertyMatcher[];
  havingAssertion?: boolean;
  connectToEntityTypes?: string[];
}

export interface EntityFilterPropertyMatcher {
  id: number;
  name: string;
  value: string | number;
  op: StringRules | NumberRules;
  type: EntityPropertyTypes;
  uom?: string | null;
}

export enum StringRules {
  EQUALS = '=',
  NOT_EQUALS = '<>',
  STARTS_WITH = 'STARTS WITH',
  CONTAINS = 'CONTAINS',
  IS_NULL = 'IS NULL',
  IS_NOT_NULL = 'IS NOT NULL',
}

export enum NumberRules {
  EQUALS = '=',
  NOT_EQUALS = '<>',
  GREATER = '>',
  GREATER_OR_EQUAL = '>=',
  LESS = '<',
  LESS_OR_EQUAL = '<=',
  IS_NULL = 'IS NULL',
  IS_NOT_NULL = 'IS NOT NULL',
}

export interface EntityAdvancedSearchForm {
  entities: EntityAdvancedSearchFormEntityItem[];
}

export enum EntityPropertyTypes {
  STRING = 'String',
  DOUBLE = 'Double',
}

export interface EntityAdvancedSearchFormEntityItem {
  entityType: string;
  name: string[];
  properties: SearchFormProperty[];
  active: boolean;
  connectedEntityTypes: string[];
}

export interface SearchFormProperty {
  name: string;
  type: EntityPropertyTypes;
  uom: string | null;
}
export interface GrafanaDashboardsContainerState {
  grafaDashboardUrl: string;
  isUpdatedTime: any;
  copyDashboardUrl: any;
}

export interface GrafanaDashboardParams {
  iframeUrl?: string;
  external?: string;
}
export interface GrafanaExploreContainerState {
  grafaMetricsUrl: string;
  isUpdatedTime: any;
  copyMetricUrl: any;
}

export interface GrafanaExploreParams {
  iframeUrl?: string;
  external?: string;
  start?: string;
  end?: string;
}

export interface IncidentsContainerState {
  incidentSearch?: string;
  expandedItems: string[];
  sortBy: 'endTime' | 'type';
  filterByType: string[];
}

export interface IncidentGroup {
  name: string;
  type: 'slo_alert' | 'assertion_alert';
  detail: GroupDetails;
  incidents: Incident[];
  id: string;
}

export interface GroupedIncident extends IncidentGroup {
  nestedItems?: IncidentGroup[];
}

export interface GroupDetails {
  action?: string;
  sloName?: string;
  sloTargetName?: string;
  threshold?: string;
  alertName?: string;
  assertionName?: string;
  assertionNames?: string[];
  displayLabels?: Record<string, string>;
  labels?: Record<string, string>;
  name?: string;
  type?: string;
  scope: Scope;
}

export interface IncidentResponse {
  incidentGroups: IncidentGroup[];
  chartName: string;
}

export interface ProcessedIncidentResponse {
  incidentGroups: GroupedIncident[];
  chartName: string;
}

export interface TopIncidentsResponse {
  incidentSummaries: IncidentSummary[];
}

export interface IncidentSummary {
  incidents: number;
  name: string;
  scope: Scope;
  status: boolean;
  type: string;
}

export interface Incident {
  burnRate: number;
  endTime: number;
  startTime: number;
  summary: string;
  severity: AssertionSeverity;
}

export interface IncidentsQueryParams {
  start?: string;
  end?: string;
  search?: string;
  expand?: string;
  expandEnv?: string;
  expandSite?: string;
}

export interface InfoTabContainerState {
  default: string;
}

export interface LogConfig {
  tool: string;
  url: string;
  dateFormat: string;
  defaultSearchText?: string;
  errorFilter?: string;
  httpResponseCodeField?: string;
  labelToLogFieldMapping: Record<string, string>;
  index?: string;
  orgId?: string;
  dataSource: string;
}

export type LogIntegration = {
  defaultConfig?: boolean;
  name: string;
  envsForLog?: string[];
  sitesForLog?: string[];
  logConfig: LogConfig;
};

export interface AwsCloudWatchConfig {
  name?: string;
  externalId?: string;
  accessKey?: string;
  accountID: string;
  assumeRoleARN?: string;
  secretKey?: string;
  paused?: boolean;
}

export interface AwsExporterConfig {
  alertForwardUrl: string;
  assertsUser: string;
  assumeRole: string;
  delay: number;
  dimensionToLabels: {
    dimensionName: string;
    entityType: string;
    mapToLabel: string;
    namespace: string;
  }[];
  discoverECSTasks: true;
  discoverResourceTypes: string[];
  ecsTargetSDFile: string;
  ecsTaskScrapeConfigs: {
    containerDefinitionName: string;
    containerPort: number;
    metricPath: string;
  }[];
  getResourcesResultCacheTTLMinutes: number;
  listFunctionsResultCacheTTLMinutes: number;
  listMetricsResultCacheTTLMinutes: number;
  logScrapeDelaySeconds: number;
  namespaces: AwsExporterConfigNamespace[];
  numTaskThreads: 0;
  regions: string[];
  scrapeInterval: 0;
  tagExportConfig: {
    envTags: string[];
    excludePatterns: string[];
    excludeTags: string[];
    includePatterns: string[];
    includeTags: string[];
  };
  tenant: string;
}

export interface AwsExporterConfigNamespace {
  dimensionFilters: Record<string, string>;
  metrics: [
    {
      name: string;
      scrapeInterval: number;
      stats: string[];
    }
  ];
  name: string;
  enabled: boolean;
}

export interface LogIntegrationsResponse {
  environments: LogIntegration[];
  supportedLogTools: string[];
}

export interface LogConfigForm {
  dataSource: string;
  name: string;
  defaultSearchText: string;
  errorFilter: string;
  labelToField: { label: string; field: string }[];
  envs: string[];
  sites: string[];
}

export type TraceIntegrationTool = 'Jaeger' | 'CloudTrace' | 'AWSXRay' | 'Tempo';

export interface TraceConfigForm {
  name: string;
  envs: string[];
  sites?: string[];
  dataSource: string;
  serviceLabel: string | undefined;
  operationLabel: string | undefined;
  labelToTag: { label: string; tag: string }[];
}

export interface TraceConfig {
  tool: TraceIntegrationTool;
  url: string;
  labelToTagMapping: Record<string, string>;
  serviceLabel: string | undefined;
  operationLabel: string | undefined;
  isElasticBackend?: boolean;
  addOutboundContext: boolean;
  assertsCollector: boolean;
  ingestedByAgent?: boolean;
  dataSource: string;
  orgId: string;
}

export interface TraceIntegration {
  name: string;
  envsForTrace: string[];
  sitesForTrace?: string[];
  defaultConfig?: boolean;
  traceConfig: TraceConfig;
}

export interface KpiTabContainerState {
  kpiDetails: EntityKpiDetails | null;
  isKpiDetailsLoading: boolean;
  expandedRows: string[];
}

export interface ManageAssertionsContainerState {
  // groupRuleData: AssertionGroup[];
  itemToPopulate?: Partial<Record<string, string>>;
  start: number | string;
  end: number | string;
}

export interface ThresholdRulesRes {
  customThresholds: ThresholdItem[];
  globalThresholds: ThresholdItem[];
}

export interface ThresholdItem {
  expr: string;
  record: string;
  assertion?: string;
  labels?: Record<string, string>;
}

export interface NotificationConfig {
  name: string;
  matchLabels: {
    alertname?: string;
    job?: string;
    asserts_request_type?: string;
    asserts_request_context?: string;
    asserts_error_type?: string;
    additional_labels?: string;
    alertgroup?: string;
    asserts_env?: string;
    asserts_slo_name?: string;
    asserts_source?: string;
    asserts_resource_type?: string;
    container?: string;
    topic?: string;
    asserts_severity?: string;
  };
  silenced?: boolean;
  alertLabels:
    | {
        asserts_receiver?: string;
      }
    | undefined;
  for?: string;
}

export interface AssertionHealthItem {
  name: string;
  matchLabels: {
    alertname: string;
  };
}

export interface AssertionHealthRes {
  alertConfigs: AssertionHealthItem[];
}

export interface AssertionGroupRule {
  alert: string;
  expr: string;
  for: string;
  dot?: 'warning' | 'critical';
  count?: number;
  editing?: boolean;
  groupName: string;
  annotations: {
    description: string;
    summary: string;
  };
  labels: {
    asserts_alert_category: string;
    asserts_alert_type: string;
    asserts_entity_type: string;
    asserts_severity: string;
  };
}

export interface AssertionGroup {
  name: string;
  rules: AssertionGroupRule[];
}

export interface CustomRule {
  alert: string;
  expr: string;
  for: string;
  labels: {
    asserts_severity: string;
    asserts_alert_category: string;
    asserts_entity_type: string;
  };
}

export interface ManageSloContainerState {
  sloConfigs: SloConfigModel[];
  search: string;
}

export interface SloObjective {
  ratio: number;
  value: number;
  name: string;
  window: {
    days: number;
  };
}

export enum Comparator {
  GreaterThan = '>',
  GreaterThanOrEqualTo = '>=',
  EqualTo = '==',
  NotEqualTo = '!=',
  LessThanOrEqualTo = '<=',
  LessThan = '<',
}

export type SloConfigModel = {
  name: string;
  uuid: string;
  active: boolean;
  kind: 'SLO';
  status?: string;
  indicator: {
    thresholdComparator: Comparator;
    kind: SloType;
    badEventCount?: string;
    totalEventCount?: string;
    measurement?: string;
    threshold?: string;
    name?: string;
    gauge?: boolean;
  };
  entitySearch: string;
  objectives: SloObjective[];
  readOnly: { provenance: string };
};

export interface SavedSearchesContainerState {
  default: string;
}

export interface SettingsContainerState {
  appRoles?: UserRole[];
  relabelRulesSort?: Sort;
  prometheusConfigListSort?: Sort;
}

export interface RelabelRule {
  index?: number;
  selector: string;
  replacement: string;
  drop: boolean;
  join_labels: string[];
  ranked_choice: string[] | undefined;
  target_label: string | undefined;
}

export interface RelabelRuleParams {
  type: RelabelRuleType;
}

export type RelabelRuleType = 'prologue' | 'epilogue';

export interface GlobalApiCredentials {
  created: number;
  key: string;
  role: UserRole;
  secret: string;
}

export interface SloContainerState {
  endTime: number | string;
  isLoading: boolean;
  expandedItems: string[];
  itemsZoomedLeft: Record<string, number>;
  itemsZoomedWidth: Record<string, number>;
  scrollPosition: number;
  search: string;
  order: Order;
  orderBy: string;
}

export interface Slo {
  name: string;
  uuid: string;
  action: string;
  badEventQuery?: string;
  totalEventQuery?: string;
  measurementQuery?: string;
  type: SloType;
  sloTargetDtos: SloTarget[];
  scope?: Scope;
  average_actualSli?: number;
  average_incidentCount?: number;
  average_budgetRemainingPersent?: number;
  average_recentBurnRate?: number;
  average_incidentStatus?: number;
}

export interface SloTarget {
  hash: string;
  name: string;
  description: string;
  targetSli: number;
  actualSli: number;
  complianceWindowDto: {
    type: string;
    numDays: number;
  };
  status: string;
  fastBurnViolatingSince: number;
  slowBurnViolatingSince: number;
  errorBudget: number;
  errorBudgetBalance: number;
  badCount: number;
  mostRecentErrorRate: number;
  threshold: number;
  totalCount: number;
  incidentCount: number;
  oneHourBurnRate: number;
  recentBurnRate: number;
  oneHourErrorBudget: number;
  fastBurnThreshold: number;
  incidentTriggerDescription: string;
  budgetRemainingPersent?: number;
  fastMin?: number;
  slowMin?: number;
  incidentStatus?: number;
}

export interface SloResponse {
  slos: Slo[];
  chartNames: (ChartName | string)[];
}

export interface SloChartResponse {
  title: string;
  metrics: Metric[];
  thresholds: Threshold[];
  endTimeMs: number;
  startTimeMs: number;
  stepMs: number;
  badEventQuery?: string;
  totalEventQuery?: string;
  measurementQuery?: string;
}

export enum SloType {
  Request = 'Request',
  Occurrence = 'Occurrence',
}
export enum ChartName {
  ErrorBudgetBurndown = 'Error Budget Burndown',
  SLI = 'SLI',
}

export interface SloQueryParams {
  sloName: string;
  sloTargetName: string;
  sloEnv: string;
  sloSite: string;
  search?: string;
  endTime?: string;
}

export interface SloConfig {
  name: string;
  indicatorKind: string;
  status: string;
  objectives: string[];
  entitySearch: string;
}

export interface ZoomedPos {
  startX: number;
  endX: number;
}

export interface SloIncident {
  incidents: {
    startTime: number;
    endTime: number;
    severity: 'critical' | 'warning' | 'info';
  }[];
}

export interface ThemeProviderContainerState {
  theme: ThemeType;
  currentAppTheme: AppThemeType;
}

export type ThemeType = 'light' | 'dark' | 'system';
export type AppThemeType = 'light' | 'dark';

export interface TopInsightsContainerState {
  assertionStatUrl: string;
  scaleType: 'log' | 'linear';
  topInsightsSearches: string[];
  expandedSearches: string[];
  pinnedSearches: string[];
  selectedViewTypeMap: Record<string, TopInsightsViewType>;
}

export enum TopInsightsViewType {
  SUMMARY = 'SUMMARY',
  MIND_MAP = 'MIND_MAP',
  KPI = 'KPI',
}

export interface TopInsightQueryParams {
  start?: string;
  end?: string;
  search?: string;
  env?: string[];
  site?: string[];
}

export type TopInsightsSort = Sort<'percentage' | 'name' | 'type'>;

export interface GlobalSearchAction {
  show?: boolean;
  index: number;
  active: boolean;
  setActionCursor: (index: number) => void;
  definition: Definition | string;
  enterPressed: boolean;
  onSelectQuery: (query: string) => void;
  startMs: number;
  endMs: number;
}

export enum LatencyType {
  P99 = 'p99',
  P95 = 'p95',
  AVERAGE = 'average',
}

export enum ServiceKPIType {
  REQUEST_RATE = 'asserts:request:rate5m',
  ERROR_RATE = 'asserts:error:ratio',
  LATENCY_P99 = 'asserts:latency:p99',
  LATENCY_P95 = 'asserts:latency:p95',
  LATENCY_AVERAGE = 'asserts:latency:average',
}

export interface ServiceKpiRow {
  asserts_request_type?: string;
  asserts_request_context?: string;
  [ServiceKPIType.REQUEST_RATE]?: string;
  [ServiceKPIType.ERROR_RATE]?: string;
  [ServiceKPIType.LATENCY_P99]?: string;
  [ServiceKPIType.LATENCY_P95]?: string;
  [ServiceKPIType.LATENCY_AVERAGE]?: string;
}

export interface GrafanaDatasource {
  uid: string;
  id: number;
  url: string;
  type: string;
  //...
}

export interface DashboardConfig {
  dashboardUri: string;
  query: string;
  alertCategory: string[];
  overrideEntityUri: boolean;
}

export interface CustomDashboardResponse {
  kpiDashConfigs: {
    entityType: string;
    dashConfigDto: DashboardConfig[];
  }[];
}

export interface GrafanaDashboardConfig {
  id: number;
  uid: string;
  title: string;
  uri: string;
  url: string;
  slug: string;
  type: string;
  tags: string[];
  isStarred: boolean;
  folderTitle?: string;
}

export interface GrafanaDashboard {
  id: number;
  uid: string;
  title: string;
  url: string;
  type: 'dash-folder' | 'dash-db';
  tags: string[];
  isStarred: boolean;
}

export type GroupedCountEntity = Pick<Entity, 'type' | 'scope'> & {
  count: number;
  warning?: string;
  critical?: string;
  info?: string;
  color?: string;
};

export interface SelectOption {
  value: string | number;
  label: string | React.JSX.Element;
}

export interface PromQuery extends DataQuery {
  expr?: string;
  instant?: boolean;
  range?: string;
}

export interface TempoQuery extends DataQuery {
  search?: string;
  query?: string;
  limit?: number;
  tableType: 'traces' | 'spans';
}

export interface LokiQuery extends DataQuery {
  expr?: string;
}

export interface GrafanaPlugin {
  id: number;
  status: string;
  slug: string;
}

export interface AssertsAppJsonData {
  stackId: string;
  orgId: string;
  skipBackendSetup: boolean;
  backendUrl: string;
  basicAuthUser: string;
  instanceUrl: string;
}

export enum GrafanaSloType {
  FailureThreshold = 'failureThreshold',
  FailureRatio = 'failureRatio',
}

export type GrafanaSlo = {
  uuid: string;
  name: string;
  labels: Array<{
    key: string;
    value: string;
  }>;
  destinationDatasource: {
    uid: string;
  };
  objectives: { window: string; value: number }[];
  query: SloFailureRatioQuery | SloFailureThresholdQuery;
  alerting: SloFailureRatioAlerting | SloFailureThresholdAlerting;
  searchExpression: string;
  readOnly: { provenance: string };
};

export interface SloFailureRatioQuery {
  failureRatio: {
    groupByLabels: string[];
    totalMetric: {
      prometheusMetric: string;
      type: 'counter' | 'gauge';
    };
    failureMetric: {
      prometheusMetric: string;
      type: 'counter' | 'gauge';
    };
  };
  type: GrafanaSloType.FailureRatio;
}

interface AlertingAnnotation {
  key: string;
  value: string;
}

export interface SloFailureThresholdQuery {
  failureThreshold: {
    groupByLabels: string[];
    threshold: {
      operator: Comparator;
      value: number;
    };
    failureThresholdExpression: string;
  };
  type: GrafanaSloType.FailureThreshold;
}

interface SloFailureThresholdAlerting {
  fastBurn: { annotations: AlertingAnnotation[] };
}
interface SloFailureRatioAlerting {
  fastBurn: { annotations: AlertingAnnotation[] };
  slowBurn: { annotations: AlertingAnnotation[] };
}

export type LabelFilter = {
  name: string;
  operator: string;
  values?: string[];
};

export interface ConnectedDataItem {
  filters: LabelFilter[];
  vendors: string[];
  envLabel: string;
  siteLabel: string;
  envLabelValues: string[];
  siteLabelValues: string[];
}
export type ConnectedDataForm = Pick<
  ConnectedDataItem,
  'filters' | 'vendors' | 'envLabel' | 'siteLabel' | 'envLabelValues' | 'siteLabelValues'
>;

export enum AssertsBackendStatus {
  NOT_INITIALIZED = 'not_initialized',
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  PROCESSING_PENDING = 'processing_pending',
  LEGACY = 'legacy',
  COMPLETE = 'complete',
  PENDING_DISABLE = 'pending_disable',
  PROCESSING_PENDING_DISABLE = 'processing_pending_disable',
  AWAITING_READINESS = 'awaiting_readiness',
}
export enum AssertsExtensionPoints {
  SLO_DETAILS_DRAWER_V1 = 'plugins/grafana-asserts-app/slo/drawer/v1',
}

export enum AssertsSanityCheckNames {
  ISTIO = 'istio',
  KUBERNETES = 'Kubernetes',
  OTEL_TRACES = 'OTel Traces',
}

export interface SanityCheckResults {
  failedSanityCheckSteps: {
    checkName: AssertsSanityCheckNames;
    dataPresent: boolean;
    stepResults: {
      name: string;
      blockers?: string[];
      warnings?: string[];
    }[];
  }[];
}
